<script lang="ts" setup>
import { computed } from "vue";
import UserLoginLogsGrid from "@/components/iam/UserLoginLogsGrid.vue";

const props = defineProps({
  userId: {
    type: String,
    default: "",
  },
});

const preFilter = computed(() => {
  return [
    {
      fieldName: "userid",
      exact: [props.userId],
    },
  ];
});
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <user-login-logs-grid :preFilter="preFilter"></user-login-logs-grid>
  </v-container>
</template>
